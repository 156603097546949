import { MATINAL_ID, VERTICAL } from '@App/libs/utils/constants/pro'
import { removeSpecialChars } from '@App/libs/utils/geral'
import axios from 'axios'

axios.defaults.baseURL = process.env.NEXT_PUBLIC_BACKOFFICE_API
axios.defaults.headers.post['Content-Type'] = 'application/json'

export const axiosJOTA = axios.create({
    baseURL: process.env.NEXT_PUBLIC_BACKOFFICE_API
})

/**
 * Profile User API
 * Me - Return data from the user
 */
export const ProfileAPI = {
    me: () =>
        `${process.env.NEXT_PUBLIC_BACKOFFICE_API}/api/auth/users/profiles/me/`,
    resetPassword: (email: string) =>
        `${process.env.REACT_APP_BO_API}/auth/users/forgot-password?email=${email}&context=pro`,
    recentNewsletter: (page = '1', limit = '3') =>
        `${process.env.NEXT_PUBLIC_BACKOFFICE_API}/newsletters/campaigns/latest/?page=${page}&limit=${limit}&exclude=matinal`
}

/**
 * JOTA PRO Api
 * Return data from JOTA PRO Content
 * @param {string} slug
 * @param {string} token
 * @return {Promise}
 */
export const jotaPROApi = (slug: string, token: string) => {
    return new Promise((resolve, reject) => {
        axiosJOTA
            .get(`${process.env.NEXT_PUBLIC_PRO_NEWSLETTERS}/slug/${slug}`, {
                headers: { Authorization: token }
            })
            .then((resp) => resolve(resp))
            .catch((error) => reject(error))
    })
}

export const getLastPRONews = () => {
    return new Promise((resolve, reject) => {
        axiosJOTA
            .post(`${process.env.NEXT_PUBLIC_OPENSEARCH}`, {
                from: 0,
                size: 4,
                query: {
                    match: {
                        'source.type': 'cms.newsletter'
                    }
                },
                sort: [
                    {
                        'source.published_at': {
                            order: 'desc'
                        }
                    }
                ]
            })
            .then((resp) => resolve(resp))
            .catch((error) => reject(new Error(error.message)))
    })
}

/**
 * Get Last Content from JOTA PRO by OpenSearch
 * @param {string} type
 * @param {number} size
 * @return {Promise}
 */
export const getLastProcontent = (type: string, size = 4) => {
    return new Promise((resolve, reject) => {
        const OPENSEARCH_API = process.env.NEXT_PUBLIC_OPENSEARCH
        const OPENSEARCH_TOKEN = process.env.NEXT_PUBLIC_OPENSEARCH_TOKEN

        type = removeSpecialChars({ str: type })

        if (!OPENSEARCH_API || !OPENSEARCH_TOKEN) return

        const query = {
            from: 0,
            size: size,
            query: {
                bool: {
                    filter: [
                        { term: { 'source.type': 'cms.newsletter' } },
                        { term: { 'vertical.slug': type } }
                    ],
                    must_not: [
                        {
                            bool: {
                                must: [
                                    { term: { 'categories.id': MATINAL_ID } },
                                    {
                                        term: {
                                            'vertical.slug':
                                                VERTICAL.TRIBUTOS.toLowerCase()
                                        }
                                    }
                                ]
                            }
                        }
                    ]
                }
            },
            sort: [
                {
                    'source.published_at': {
                        order: 'desc'
                    }
                }
            ]
        }

        axiosJOTA
            .post(OPENSEARCH_API, query, {
                headers: { Authorization: OPENSEARCH_TOKEN }
            })
            .then((response) => resolve(response.data.hits.hits))
            .catch((error) => reject(new Error(error.message)))
    })
}

/**
 * Get content by term from OpenSearch or Wordpress API
 * @param {contentType} contentType
 * @param {string} term
 * @param {string} termPrettyName
 * @param {number} page
 * @return {Promise}
 */
export const getPostsByTypeContent = async (
    contentType: 'pro-info' | 'info',
    term: string,
    termPrettyName: string,
    page = 1
) => {
    try {
        switch (contentType) {
            case 'pro-info': {
                const data: any = await getContentFromOpenSearch(
                    term,
                    termPrettyName,
                    page
                )
                const {
                    hits: { hits: search, total }
                } = data

                return { search, total, type: 'pro-info' }
            }
            case 'info': {
                const url = `${process.env.NEXT_PUBLIC_JOTAINFO}/wp-json/ju/v1/posts?category=${term}&page=${page}&per_page=10`
                const response = await fetch(url)
                const posts = await response.json()

                return { posts, type: 'info' }
            }
            default:
                throw new Error('Invalid content type')
        }
    } catch (error: any) {
        return Promise.reject(new Error(error))
    }
}

/**
 * Get content from OpenSearch
 * by term, page and contentType
 * @param {string} term
 * @param {string} termPrettyName
 * @param {number} page
 * @param {number} size
 * @param {verticalSlug | null} vertical
 */
export const getContentFromOpenSearch = async (
    term: string,
    termPrettyName: string,
    page = 1,
    size = 10,
    vertical = null
) => {
    return new Promise((resolve, reject) => {
        if (
            !process?.env?.NEXT_PUBLIC_OPENSEARCH ||
            !process?.env?.NEXT_PUBLIC_OPENSEARCH_TOKEN
        ) {
            reject(new Error('OS failed'))
            return
        }

        const OS_STEP_1_PAGINATION = {
            from: (page - 1) * size,
            size: size
        }

        const OS_STEP_2_QUERY = {
            query: {
                bool: {
                    should: [
                        {
                            bool: {
                                filter: [
                                    {
                                        multi_match: {
                                            query: termPrettyName,
                                            type: 'phrase',
                                            fields: [
                                                'title.headline',
                                                'title.hat',
                                                'title.subhead',
                                                'content.plain'
                                            ]
                                        }
                                    },
                                    {
                                        match: {
                                            'source.type': 'cms.newsletter'
                                        }
                                    },
                                    ...(vertical !== null
                                        ? [
                                              {
                                                  match: {
                                                      'vertical.slug': vertical
                                                  }
                                              }
                                          ]
                                        : [])
                                ]
                            }
                        },
                        {
                            bool: {
                                filter: [
                                    {
                                        match: {
                                            'source.type': 'wp.post'
                                        }
                                    },
                                    {
                                        match: {
                                            'categories.slug.keyword': term
                                        }
                                    },
                                    {
                                        term: {
                                            'source.recycled': false
                                        }
                                    }
                                ]
                            }
                        }
                    ],
                    minimum_should_match: 1
                }
            }
        }

        const OS_STEP_3_ORDER = {
            sort: [
                {
                    'source.published_at': {
                        order: 'desc'
                    },
                    _score: {
                        order: 'desc'
                    }
                }
            ]
        }

        const OS_STEP_4_HIGHLIGHT = {
            highlight: {
                pre_tags: [''],
                post_tags: [''],
                fields: {
                    'content.plain': {}
                }
            }
        }

        const OS_QUERIE = {
            ...OS_STEP_1_PAGINATION,
            ...OS_STEP_2_QUERY,
            ...OS_STEP_3_ORDER,
            ...OS_STEP_4_HIGHLIGHT
        }

        const searchDataAPI = fetch(process?.env?.NEXT_PUBLIC_OPENSEARCH, {
            method: 'post',
            headers: new Headers({
                Authorization: process.env?.NEXT_PUBLIC_OPENSEARCH_TOKEN || '',
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify(OS_QUERIE)
        })

        searchDataAPI
            .then((response) => resolve(response.json()))
            .catch((error) => reject(new Error(error)))
    })
}
