import { IconsType } from '@JOTAJornalismo/jota-design-system/dist/jota-hooks/changeicons'
import { verticalI } from '../components/home/second-scroll/Vertical.component'
import { colors } from '../components/search/searchContants'
import { JU_TOKEN } from './constants/global'
import { dataLayerI, VerticalI } from '../interfaces/PRO.interfaces'
import { NextResponse } from 'next/server'

/**
 * JWT Token - Decode
 * @param {string} token
 * @return {object} object
 */
export const decodeJWT = (token: string): unknown => {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map((c) => {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
            })
            .join('')
    )

    return JSON.parse(jsonPayload)
}

/**
 * Return if window global is loaded
 * @return {boolean}
 */
export const isWindowLoaded = (): boolean => {
    return typeof window !== 'undefined'
}

/**
 * Log Screen
 */
export const { log } = console

/**
 * Validate Email
 * Description: Check if email is valid or not
 * @param {string} email
 * @return {boolean}
 */
export const validateEmail = (email: string): boolean => {
    const re = /^[^@]+@[^@]+\.[^@\.]{2,}$/
    return re.test(String(email).toLowerCase())
}

/**
 * Get ID Token from Cognito
 * from Local Storage
 * @param {string} email
 * @return {Promisse}
 */
export const getToken = () => {
    return new Promise((resolve, reject) => {
        if (localStorage?.getItem(JU_TOKEN) !== null) {
            const tokenTemp = localStorage?.getItem(JU_TOKEN)
            if (tokenTemp) {
                resolve(JSON.parse(tokenTemp).idToken)
            }
            reject(new Error('Não foi possível obter o token'))
        }
        reject(new Error('Não foi possível obter o token'))
    })
}

/**
 * Return Icon by string value
 * @param {string} value
 * @return {string}
 */
export const getIconByString = (value: string): IconsType => {
    if (value.includes('relatorio-especial'))
        return 'jotaNewsletterRelatorioEspecial'
    else if (value.includes('matinal')) return 'jotaNewsletterMatinal'
    else if (value.includes('impacto-crise'))
        return 'jotaNewsletterImpactoCrise'
    else if (value.includes('poder-risco')) return 'jotaNewsletterRiscoPolitico'
    else if (value.includes('tributos-direto-corte'))
        return 'jotaNewsletterDiretoCorte'
    else if (value.includes('poder-apostas'))
        return 'jotaNewsletterApostasSemana'
    else if (value.includes('tributos-apostas'))
        return 'jotaNewsletterApostasSemana'
    else if (value.includes('tributos-direto-carf'))
        return 'jotaNewsletterDiretoCarf'
    else if (value.includes('direto-legislativo'))
        return 'jotaNewsletterDiretoLegislativo'
    else if (value.includes('insights')) return 'jotaNewsletterInsightsCarf'
    else if (value.includes('poder-impacto'))
        return 'jotaNewsletterImpactoCrise'
    return 'jotaNewsletterRelatorioEspecial'
    // else return 'jotaNewsletterImpactoCrise'
}

/**
 * Generate UUID
 * @return {string}
 */
export function uniqueID() {
    return Math.random().toString(16).slice(2)
}

/**
 * Check if element is visible on screen
 * @param {Element} elm
 * @return {boolean}
 */
export function checkVisible(elm: Element) {
    const rect = elm.getBoundingClientRect()
    const viewHeight = Math.max(
        document.documentElement.clientHeight,
        window.innerHeight
    )
    return !(rect.bottom < 0 || rect.top - viewHeight >= 0)
}

/**
 * Return color by Vertical
 * @param {verticalI} vertical
 * @return {string}
 */
export const getColorByVertical = (vertical: verticalI) => {
    switch (vertical.toLowerCase()) {
        case 'poder':
            return 'jotaBlue'
        case 'tributos':
            return 'jotaGreen'
        case 'saude':
            return 'jotaPurple'
        case 'saúde':
            return 'jotaPurple'
    }
}

/**
 * MailChimp Table Sanitize
 * Description: Clean Mailchimp HTML table
 * @param {string} html
 * @return {string} new html
 */
export const cleanMCTable = (html: string) => {
    if (html) {
        /**
         * Clean by replace all tags
         */
        document
            .querySelector('h4.null')
            ?.removeAttribute('style') as unknown as HTMLElement

        const el = document.querySelector('h4.null') as HTMLElement

        if (el) {
            el.style.textAlign = 'center'
        }

        return html
            .replaceAll('!important', '')
            .replaceAll(
                `templateContainer{\n\t\t\tmax-width:600px !important;\n\t\t}`,
                ''
            )
            .replaceAll('padding-top: 9px;', '')
            .replaceAll("b'", '')
            .replaceAll('\\n', '')
            .replaceAll('\\t', '')
            .replaceAll('&nbsp;', ' ')
            .replaceAll('max-width: 600px !important;', '')
            .replaceAll('width:600px !important;', '')
            .replaceAll('!important', '')
            .replaceAll('600px', 'inherit')
            .replaceAll('20px', '1.5rem')
            .replaceAll('line-height: 200%', 'line-height: 2rem;')
            .replaceAll('font-weight: normal', 'font-weight: 700')
            .replaceAll(
                "font-family:'Helvetica Neue', Helvetica, Arial, Verdana, sans-serif;",
                "font-family:'Roboto', sans-serif !important;"
            )
            .replaceAll(
                "font-family:'Helvetica';",
                "font-family:'Roboto', sans-serif !important;"
            )
            .replaceAll('align="center"', 'align="left"')
            .replaceAll('#2BAADF', `color: ${colors.black.jotaBlack}!important`)
            .replaceAll('text-align:center;', 'text-align:inherit;')
            .replaceAll('text-align: center;', 'text-align:inherit;')
            .replaceAll('#FF0000', `${colors.gray.jotaGrayDarker}`)
            .replaceAll('background-color: #404040', 'background-color: none')
            .replaceAll('color: #F2F2F2', `color: ${colors.black.jotaBlack}`)
            .replaceAll(
                'color= #ff0000',
                `color: ${colors.gray.jotaGrayDarker}`
            )
            .replaceAll(
                'color: #73757f',
                `color: ${colors.black.jotaBlack}!important`
            )
            .replaceAll(
                'color: #9899A2',
                `color: ${colors.black.jotaBlack}!important`
            )
            .replaceAll('28px', '2.5rem')
            .replaceAll('line-height: 200%', 'line-height: 2.5rem;')
            .replaceAll('font-weight: normal', 'font-weight: 700')
            .replaceAll('16px', '1.125rem')
            .replaceAll('line-height: 125%', 'line-height: 1.75rem;')
            .replaceAll('font-weight: normal', 'font-weight: 400')
            .replaceAll('color: #5588ff', `color: ${colors.black.jotaBlack}`)
            .replaceAll('color: #3f9b7f', `color: ${colors.black.jotaBlack}`)
            .replaceAll('font-size: 1.5rem;', 'font-size: 2rem;')
            .replaceAll('line-height: 1.75rem', 'line-height: 2.5rem;')
            .replaceAll('letter-spacing: normal;', 'letter-spacing: 0px;')
            .replaceAll('max-width: 1200px;', 'max-width: 100%;')
    }
}

/**
 * Make uuid
 * @return {string}
 */
export function generateUUID() {
    // Public Domain/MIT
    return Date.now().toString(36) + Math.random().toString(36).substring(2)
}

/**
 * Decode special characters
 * @param {string} text
 * @return {string} text
 */
export const decodeSpecialCharacters = (text: string): string => {
    if (text) {
        return text.replace(/&#(\d+);/g, (_, dec) => {
            return String.fromCharCode(dec)
        })
    } else {
        return ''
    }
}

/**
 * Generate Number from Hash (text)
 * @param {string} text
 * @return {int}
 */
export function generateNumericHash(text: string) {
    const COSIN_NUMBER_FACTOR = 1000000007
    const SIZE = 31

    let hash = 0
    for (let i = 0; i < text.length; i++) {
        const char = text.charCodeAt(i)
        hash = (hash * SIZE + char) % COSIN_NUMBER_FACTOR
    }
    return hash
}

export const returnPROLink = (
    slug: string,
    vertical: VerticalI,
    category: string
) => {
    return `/${vertical.toLowerCase()}/${category.toLowerCase()}/${slug}?vertical=${vertical.toLowerCase()}`
}

export const returnProLinkByVertical = (id: any, vertical: any) => {
    const slugFinal = typeof vertical == 'string' ? vertical : vertical.slug
    return `vertical/${id}?vertical=${slugFinal.toLowerCase()}`
}

export const addDatalayer = (data: dataLayerI) => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(data)
}

export const wordpressURL = process.env.NEXT_PUBLIC_PORTAL_URL
export const sitemapPerPage = process.env.NEXT_PUBLIC_ITEM_PER_SITEMAP
export const frontendUrl = process.env.NEXT_PUBLIC_JU

export const vercelLog = (
    message: string,
    type: 'error' | 'warning' | 'normal' = 'normal'
) => {
    const JU_LOGS = 'JOTA JU ' + '[ ' + new Date().toISOString() + ' ] '
    if (type === 'error') {
        console.error(`${JU_LOGS}: ${message}`)
    } else if (type === 'warning') {
        console.warn(`${JU_LOGS}: ${message}`)
    } else {
        console.log(`${JU_LOGS}: ${message}`)
    }
}

/**
 * Remove accents from a string
 * @param {Object} param - The parameter object
 * @param {string} param.str - The string to remove accents from
 * @return {string} The string without accents
 */
export function removeSpecialChars({ str }: { str: string }): string {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

const EXPIRES = new Date('Wed, 11 Jan 1984 05:00:00 GMT').toUTCString()

export const setCommonHeaders = (
    response: NextResponse<unknown>,
    lastModifiedDate = new Date().toUTCString(),
    content_type: 'info' | 'pro-info' = 'info',
    content_id = '',
    type = ''
) => {
    response.headers.set('Last-Modified', lastModifiedDate)
    response.headers.set('X-Powered-By', 'JOTA Tecnologia')
    response.headers.set('Expires', EXPIRES)
    response.headers.set(
        'Cache-Control',
        'public, max-age=3600, must-revalidate'
    )
    response.headers.set(
        'Strict-Transport-Security',
        'max-age=31536000; includeSubDomains; preload'
    )
    response.headers.set('X-Frame-Options', 'SAMEORIGIN')
    response.headers.set('X-Content-Type-Options', 'nosniff')
    response.headers.set('X-Content-Type', content_type)
    response.headers.set('X-Content-Page-Id', content_id)
    response.headers.set('X-Content-Page-Type', type)
}

/**
 * Get the last slug from a URL
 * @param {string} url - The URL to extract the last slug from
 * @return {string} The last slug from the URL
 */
export function getLastSlugFromUrl(url: string): string {
    const parts = url.split('/')
    return parts[parts.length - 1] || ''
}

/**
 * Get Slugs from URL
 * @param {string} url - The URL to extract the last slug from
 * @return {string} The last slug from the URL
 */
export function getSlugsFromUrl(url: string): Array<{ label: string }> {
    return url
        .split('/')
        .filter((part) => part !== '')
        .map((slug) => ({ label: slug }))
}

/**
 * Normalize a URL by removing the domain if it matches specific patterns
 * @param {string} url - The URL to normalize
 * @return {string} The normalized URL
 */
export function normalizeUrl(url: string): string {
    const domainPattern =
        /^(https?:\/\/)?(portal\.jota\.info|portal-stg\.jota\.info|localhost:9000)/
    return url.replace(domainPattern, '')
}

export enum companyRelationQuoteEnum {
    'parceiro' = 'Com parceria de',
    'patrocinador' = 'Patrocinado por',
    'apoiador' = 'Apoiado por'
}
